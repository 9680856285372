
  import { Component } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'

@Component
  export default class SupervisorTreasury extends SupervisorDashboard {
    // Methods
    beforeCreate () {
      this.getData = () => {
      }
    }

    // Getters

    // Watchers
  }
